<template>
  <a-modal
    :visible="visibleFeedback"
    @cancel="handleClose()"
    cancelText="HUỶ"
    okText="XÁC NHẬN"
    :confirmLoading="loading"
    :footer="null"
    :width="canAddNewFeedback ? '1200px' : '430px'"
    wrapClassName="modal-feedback"
    :centered="true"
  >
    <div class="feedback">
      <a-row :class="['feedback__row', canAddNewFeedback ? 'divider' : '']">
        <a-col :span="14" v-if="canAddNewFeedback">
          <div class="feedback__block feedback__update">
            <div class="feedback__title">Thêm tác nghiệp chăm khách đơn {{ codeOrder }}</div>

            <div class="feedback__label">
              Chọn tác nghiệp
              <span class="require">
                *
              </span>
            </div>
            <div class="feedback__input">
              <a-radio-group v-model="selectCareer" v-if="listFeedback.length">
                <a-radio :value="item.name" v-for="(item, index) in listFeedback" :key="index">
                  {{ item.name }}
                </a-radio>
              </a-radio-group>
            </div>
            <div class="feedback__invalid" v-if="messageInvalid.select">
              {{ messageInvalid.select }}
            </div>

            <div class="feedback__label">
              Kết quả tác nghiệp
              <span class="require">
                *
              </span>
            </div>
            <div class="feedback__error">
              <a-radio-group v-model="resultCareer" v-if="listFeedback.length && listFeedback[0].details">
                <a-radio :value="item.name" v-for="(item, index) in listFeedback[0].details" :key="index">
                  {{ item.name }}
                </a-radio>
              </a-radio-group>
            </div>
            <div class="feedback__invalid" v-if="messageInvalid.result">
              {{ messageInvalid.result }}
            </div>
            <div class="feedback__label">
              Tải ảnh minh chứng chăm khách
            </div>

            <div class="feedback__error">
              <a-upload
                class="image-uploader"
                name="image"
                listType="picture-card"
                accept="image/*"
                :fileList="imagesFeedback"
                :beforeUpload="(file, fileList) => beforeUpload(file, fileList)"
                @change="handleChange"
                :max-count="4"
                :multiple="true"
                @preview="handlePreview"
                :action="`${apiUpload}/common`"
              >
                <div v-if="imagesFeedback.length < 4">
                  <a-icon type="camera" />
                  <div class="ant-upload-text">{{ imagesFeedback.length }}/4</div>
                </div>
              </a-upload>
            </div>

            <div class="feedback__label">
              Ghi chú khác
            </div>
            <div class="feedback__input">
              <a-textarea
                v-model="note"
                placeholder="Ghi chú khác của seller..."
                :auto-size="{ minRows: 4, maxRows: 10 }"
              />
            </div>
            <div class="feedback__invalid" v-if="messageInvalid.note">
              {{ messageInvalid.note }}
            </div>
            <a-spin :spinning="loading">
              <div class="feedback__action">
                <div class="action" @click="handleClose()">Huỷ</div>
                <div class="action action__confirm" @click="handleConfirmBtn()">Xác nhận</div>
              </div>
            </a-spin>
          </div>
        </a-col>
        <a-col :span="canAddNewFeedback ? 10 : 24" flex="1">
          <div class="feedback__block feedback__list">
            <div class="feedback__title">
              Danh sách feedback đã thêm
            </div>
            <template v-if="dataFeedBack.length">
              <div class="feedback__item" v-for="data in dataFeedBackCustomer" :key="data.name">
                <div class="item__content">
                  <div class="content__title">
                    Seller chăm sóc khách online NGÀY
                    {{ moment(data.createdDate).format('DD/MM/YYYY') }}
                  </div>
                  <div class="content__user">Seller: {{ data.userAction }}</div>
                </div>
                <div class="item__fb" v-if="data.feedbackData.length">
                  <div class="fb__item">
                    <div class="fb__title">Tác nghiệp seller</div>
                    <div class="fb__detail">
                      <span>{{ data.feedbackData[0].name }} </span>
                    </div>
                  </div>
                  <div class="fb__item" v-if="data.feedbackData[0].details.length">
                    <div class="fb__title">Kết quả tác nghiệp</div>
                    <div class="fb__detail" v-if="data.feedbackData[0].details[0].name">
                      <span>{{ data.feedbackData[0].details[0].name }} </span>
                    </div>
                  </div>
                  <div class="fb__item" v-if="data.images.length">
                    <div class="fb__title">Ảnh chăm khách</div>
                    <div class="fb__img">
                      <img
                        :src="item"
                        v-for="(item, index) in data.images"
                        :key="index"
                        @click="onClickPreviewImageListFeedback(index, data.images)"
                      />
                    </div>
                  </div>
                  <div class="fb__item" v-if="data.note">
                    <div class="fb__title">Khác</div>
                    <div class="fb__detail">{{ data.note }}</div>
                  </div>
                </div>
              </div>

              <div class="feedback__loadMore" @click="getRenderListFeedBackCustomer(page + 1)" v-if="page < totalPage">
                <span>
                  Xem thêm
                </span>
              </div>
            </template>
            <div v-else class="no-data">
              Không có dữ liệu
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <modal-list-image :previewVisible.sync="previewVisible" :listImg="listImgOpen" :previewImage.sync="previewImage" />
  </a-modal>
</template>

<script>
import { RepositoryFactory } from '@/api/RepositoryFactory'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Vue from 'vue'
import { Carousel } from 'ant-design-vue'
import ModalListImage from './ModalListImage'
Vue.use(Carousel)
const feedbackRepository = RepositoryFactory.get('feedbackRepository')

export default {
  components: { ModalListImage },
  name: 'ModalFeedBack',
  data() {
    return {
      loading: false,
      listFeedback: [],
      selectCareer: '',
      resultCareer: '',
      note: '',
      messageInvalid: {
        select: '',
        result: '',
        note: '',
        image: ''
      },
      dataFeedBack: [],
      dataFeedBackCustomer: [],
      page: 1,
      totalPage: 1,
      canAddNewFeedback: true,
      imagesFeedback: [],
      previewVisible: false,
      previewImage: 0,
      apiUpload: process.env.VUE_APP_API_UPLOAD,
      listImgOpen: []
    }
  },
  props: {
    visibleFeedback: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: Number,
      default: 0
    },
    shopOrderCode: {
      type: String,
      default: ''
    },
    shopOrderId: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['canList']),
    isOpen: {
      get: function() {
        return this.visibleFeedback
      },
      set: function(value) {
        this.$emit('update:visibleFeedback', value)
      }
    },
    codeOrder: {
      get: function() {
        return this.shopOrderCode
      },
      set: function(value) {
        this.$emit('update:shopOrderCode', value)
      }
    },
    idOrder: {
      get: function() {
        return this.shopOrderId
      },
      set: function(value) {
        this.$emit('update:shopOrderId', value)
      }
    }
  },
  async created() {
    this.getListTypeConfigFeedback()
  },
  methods: {
    moment,
    getRenderListFeedBackCustomer(page) {
      this.page = page
      const start = page > 1 ? (page - 1) * 4 : 0
      const end = start + 4
      const newData = [...this.dataFeedBack].slice(start, end)
      if (page === 1) {
        this.dataFeedBackCustomer = []
      }
      this.dataFeedBackCustomer = [...this.dataFeedBackCustomer, ...newData]
    },
    async getListTypeConfigFeedback() {
      try {
        const response = await feedbackRepository.getListTypeConfigFeedbackShop()
        if (response.data) {
          this.listFeedback = response.data
        }
      } catch (error) {
        throw error
      }
    },
    async getListFeedBackCustomer() {
      try {
        const response = await feedbackRepository.getListFeedbackCustomerShop({
          customerId: this.customerId,
          shopOrderId: this.idOrder,
          shopOrderCode: this.codeOrder
        })
        if (response?.data?.length) {
          this.dataFeedBack = response.data
          this.page = 1
          this.totalPage = Math.ceil(this.dataFeedBack.length / 4)
          this.getRenderListFeedBackCustomer(1)
        } else {
          this.dataFeedBack = []
          this.totalPage = 1
          this.getRenderListFeedBackCustomer(1)
        }
      } catch (error) {
        this.dataFeedBack = []
        this.totalPage = 1
        this.getRenderListFeedBackCustomer(1)
        throw error
      }
    },
    handleClose() {
      this.note = ''
      this.selectCareer = ''
      this.resultCareer = ''
      this.isOpen = false
      this.idOrder = ''
      this.codeOrder = ''
      this.messageInvalid = {
        select: '',
        result: '',
        note: ''
      }
      this.imagesFeedback = []
      this.getRenderListFeedBackCustomer(1)
    },
    async handleConfirmBtn() {
      if (!this.selectCareer) {
        this.messageInvalid = { ...this.messageInvalid, select: 'Chưa chọn nhóm tác nghiệp' }
        return
      }
      if (!this.resultCareer) {
        this.messageInvalid = { ...this.messageInvalid, result: 'Chưa chọn kết quả tác nghiệp' }
        return
      }
      if (this.resultCareer === 'Khác' && this.note === '') {
        this.messageInvalid = { ...this.messageInvalid, note: 'Chưa nhập ghi chú' }
        return
      }
      if (this.loading) return

      const listFeedback = [
        {
          name: this.selectCareer,
          details: [
            {
              name: this.resultCareer
            }
          ]
        }
      ]
      const listImg = this.imagesFeedback.map(item => item.response[0])
      try {
        this.loading = true
        const response = await feedbackRepository.createFeedbackShop({
          customerId: this.customerId,
          shopOrderId: this.idOrder,
          shopOrderCode: this.codeOrder,
          feedbackData: listFeedback,
          note: this.note,
          type: 2,
          images: listImg
        })

        this.$notification.success({
          message: 'Thông báo',
          description: response.message || 'Thêm feedback khách hàng thành công!'
        })
        this.getListFeedBackCustomer()
        this.note = ''
        this.selectCareer = ''
        this.resultCareer = ''
        this.imagesFeedback = []
        this.messageInvalid = {
          select: '',
          result: '',
          note: '',
          image: ''
        }
      } catch (error) {
        this.loading = false
        throw error
      }
      this.loading = false
    },
    handleCancel() {
      this.previewVisible = false
    },
    handleOpenModalPreview(list) {
      this.previewVisible = true
      this.listImgOpen = list
    },
    async handlePreview(file) {
      this.previewImage = this.imagesFeedback.findIndex(item => item.thumbUrl === file.thumbUrl)
      const listImgPreview = this.imagesFeedback.map(item => item.response[0])
      this.handleOpenModalPreview(listImgPreview)
    },
    onClickPreviewImageListFeedback(indexActive, list) {
      this.previewImage = indexActive
      this.handleOpenModalPreview(list)
    },
    handleChange({ file, fileList }) {
      if (file.status) {
        this.imagesFeedback = fileList
      }
    },
    beforeUpload(file, fileList) {
      const fileListSize = fileList.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0)
      const imagesFeedbackSize = this.imagesFeedback.reduce(
        (accumulator, currentValue) => accumulator + currentValue.size,
        0
      )
      if (this.imagesFeedback.length + fileList.length > 4) {
        this.$message.error({ content: 'Bạn chỉ có thể upload nhiều nhất 4 ảnh!', key: 'errorLength' })
        return false
      }
      if (!file.type.match(/image.*/)) {
        this.$message.error({ content: 'Bạn chỉ có thể upload file ảnh', key: 'errorType' })
        return false
      }
      if ((imagesFeedbackSize + fileListSize) / 1024 / 1024 > 0.8) {
        this.$message.error({ content: 'Bạn chỉ có thể upload tổng các file ảnh nhỏ hơn 800KB!', key: 'errorSize' })
        return false
      }

      return true
    }
  },
  watch: {
    customerId: function(value) {
      if (value) {
        // const canPermNewFeedback = this.canList
        //   .filter(item => item.subject.includes('/feedback'))
        //   .filter(item => item.actions === 'Perm_Add')
        // this.canAddNewFeedback = canPermNewFeedback.length > 0
      }
    },
    shopOrderCode: function(value) {
      if (value && this.isOpen) {
        this.getListFeedBackCustomer()
      }
    }
  }
}
</script>

<style lang="scss">
.modal-feedback {
  .ant-modal-body {
    padding: 0;
  }
  .ant-radio-group {
    width: 100%;
    .ant-radio-wrapper {
      width: 50%;
      margin: 0;
      margin-bottom: 5px;
    }
  }
  .feedback {
    color: #262626;
    &__loadMore {
      text-align: center;
      padding-top: 20px;
      span {
        cursor: pointer;
        text-decoration: underline;
        font-style: italic;
        color: #1890ff;
        font-size: 14px;
        font-weight: 500;
        &:hover {
          color: #2b5277;
        }
      }
    }
    &__row {
      position: relative;
      min-height: 500px;
      &.divider::before {
        position: absolute;
        left: 58.5%;
        top: 0;
        content: '';
        height: 100%;
        width: 2px;
        background: #e8e8e8;
      }
    }
    &__title {
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      color: #262626;
    }
    &__update {
    }
    &__block {
      padding: 20px 30px 20px;
    }
    &__label {
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 10px;
      padding-top: 22px;
      color: #262626;
      span {
        color: #e54d3e;
      }
    }
    &__input {
    }
    &__error {
    }
    &__invalid {
      color: #e54d3e;
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
    }
    &__detailError {
      margin-bottom: 8px;
      .title {
        font-size: 14px;
        color: #595959;
        font-weight: 600;
        padding-bottom: 8px;
      }
      .content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 12px;
        &__text {
          border-radius: 30px;
          border: 1px solid #e8e8e8;
          background: #f6f6f6;
          font-weight: 500;
          color: #3d3d3d;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 6px 12px;
          img {
            margin-right: 4px;
          }
          &.checked,
          &:hover {
            border: 1px solid #3d3d3d;
            background: #fff;
          }
        }
      }
    }
    &__item {
      border-radius: 10px;
      border: 2px solid #222;
      padding: 20px;
      margin-top: 22px;
      .item {
        &__content {
          .content {
            &__title {
              color: #262626;
              font-size: 16px;
              font-weight: 600;
              text-transform: uppercase;
            }
            &__user {
              color: #262626;
              font-size: 15px;
              font-weight: 600;
            }
          }
        }
        &__fb {
          margin-top: 4px;
          .fb {
            &__item {
              margin-top: 4px;
              display: flex;
              flex-flow: wrap;
            }
            &__title {
              color: #767676;
              font-size: 14px;
              font-weight: 500;
              width: 35%;
              flex: auto;
            }
            &__detail {
              color: #3d3d3d;
              font-size: 14px;
              font-weight: 500;
              width: 65%;
              padding-left: 8px;
              flex: auto;
            }
            &__img {
              width: 100%;
              display: flex;
              padding-top: 3px;
              gap: 8px;
              img {
                width: 47px;
              }
            }
          }
        }
      }
    }
    &__action {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      gap: 20px;
      .action {
        border-radius: 5px;
        background: #e8e8e8;
        width: 150px;
        height: 40px;
        padding: 10px;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
        &:hover {
          background: #d2d2d2;
        }
        &__confirm {
          background: #fc3;
          &:hover {
            background: rgb(255, 218, 107);
          }
        }
      }
    }
    textarea.ant-input {
      border-radius: 5px;
      border: 1px solid #e8e8e8;
      background: #f6f6f6;
    }
  }
  .no-data {
    text-align: center;
    padding: 25px 0;
  }
}
</style>
