export default function changeDomain(url) {
  console.log(url, 'sssssssssssssssss')

  const moyoDomainList = ['goidauda', 'moyo', 'localhost']

  for (let domain of moyoDomainList) {
    if (url.includes(domain)) {
      return true
    }
  }

  return false
}
