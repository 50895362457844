<template>
  <a-modal
    v-model="localVisible"
    title="Thông tin nhân sự"
    style="top: 20px;"
    :maskClosable="false"
    width="50%"
    :bodyStyle="{ height: '200px', overflowY: 'auto' }"
    :footer="null"
  >
    <a-table
      :loading="loading"
      :columns="columns"
      :dataSource="dataSource"
      bordered
      :rowKey="(record, index) => index"
      :pagination="false"
    >
      <span slot="workShiftToday" slot-scope="record" v-html="record.workShiftToday"></span>
      <span slot="workShiftTomorrow" slot-scope="record" v-html="record.workShiftTomorrow"></span>
      <span slot="workShiftAfterTomorrow" slot-scope="record" v-html="record.workShiftAfterTomorrow"></span>
    </a-table>
  </a-modal>
</template>

<script>
import { RepositoryFactory } from '@/api/RepositoryFactory'
const StaffRepo = RepositoryFactory.get('staff')

var moment = require('moment')

export default {
  data() {
    return {
      loading: false,
      dataSource: [],
      dateNow: moment().format('DD/MM/YYYY'),
      columns: [
        {
          title: 'Mã ID',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: 'Tên nhân sự',
          dataIndex: 'staffName',
          key: 'staffName'
        },
        {
          title: 'Vị trí',
          dataIndex: 'staffType',
          key: 'staffType'
        },
        {
          title: 'Salon đang làm việc',
          dataIndex: 'salonName',
          key: 'salonName'
        },
        {
          title: 'Ca làm hôm nay',
          key: 'workShiftToday',
          scopedSlots: {
            customRender: 'workShiftToday'
          }
        },
        {
          title: 'Ca làm ngày mai',
          key: 'workShiftTomorrow',
          scopedSlots: {
            customRender: 'workShiftTomorrow'
          }
        },
        {
          title: 'Ca làm ngày kia',
          key: 'workShiftAfterTomorrow',
          scopedSlots: {
            customRender: 'workShiftAfterTomorrow'
          }
        }
      ]
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: String,
      default: undefined
    }
  },
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        console.log(val)
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {},
  async created() {
    try {
      this.loading = true

      var res = await StaffRepo.getWorkTimeInfo(this.data, this.dateNow, 1, 10)

      if (res === null || res.results.length === 0) {
        this.$message.warn('Không có thông tin!', 2)
        return
      }

      this.dataSource = res.results
    } catch (error) {
      this.$message.error(error, 2.5)
    } finally {
      this.loading = false
    }
  }
}
</script>
<style scoped>
.form-item-lable {
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
