<template>
  <a-row :gutter="[4, 4]" class="b-member-row" type="flex">
    <a-col flex="150px" class="b-member-name">
      <span class="capitalize">{{ member.memberName.toLowerCase() }}</span>
      <span> Member:</span>
    </a-col>
    <a-col flex="250px" class="b-member-time">
      từ {{ moment(member.startTime).format('DD/MM/YYYY') }} -
      {{ moment(member.endTime).format('DD/MM/YYYY') }}
    </a-col>
    <a-col>
      <span :class="dayDiff < 0 ? 'expire' : 'unexpired'">
        <span v-if="dayDiff < 0">Hết hạn</span>
        <span v-else>Còn {{ dayDiff }} ngày</span>
      </span>
    </a-col>
  </a-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ItemMemberInfo',
  computed: {
    dayDiff: function() {
      const date1 = this.member.endTime ? moment(this.member.endTime) : moment()
      return date1.diff(moment(), 'days')
    }
  },
  data() {
    return {}
  },
  props: {
    member: {
      type: Object,
      required: null
    }
  },
  methods: { moment }
}
</script>

<style lang="scss">
.b-member-row {
  padding-bottom: 8px;
}
.b-member-name {
  font-weight: 700;
}
.capitalize {
  text-transform: capitalize;
}
.expire {
  border-radius: 6px;
  border: 1px solid red;
  color: red;
  padding: 2px 12px;
}
.unexpired {
  border-radius: 6px;
  border: 1px solid green;
  color: green;
  padding: 2px 12px;
}
</style>
