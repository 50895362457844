<template>
  <a-modal :visible="isOpen" :footer="null" @cancel="handleCancel" class="modal-image" :width="1200">
    <template #closeIcon>
      <a-icon type="close-circle" theme="filled" />
    </template>
    <div :class="['arrow', previewImage === 0 ? 'disable' : '']" @click="goPrev()">
      <a-icon type="left" />
    </div>
    <div>
      <img style="width: 100%" :src="listImg[activeImg]" />
    </div>
    <div :class="['arrow', previewImage === listImg.length - 1 ? 'disable' : '']" @click="goNext()">
      <a-icon type="right" />
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'ModalListImageFeedBack',
  props: {
    previewVisible: {
      type: Boolean,
      default: false
    },
    listImg: {
      type: Array,
      default: null
    },
    previewImage: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isOpen: {
      get: function() {
        return this.previewVisible
      },
      set: function(value) {
        this.$emit('update:previewVisible', value)
      }
    },
    activeImg: {
      get: function() {
        return this.previewImage
      },
      set: function(value) {
        this.$emit('update:previewImage', value)
      }
    }
  },
  methods: {
    handleCancel() {
      this.isOpen = false
    },
    goPrev() {
      if (this.previewImage > 0) {
        this.previewImage = this.previewImage - 1
      }
    },
    goNext() {
      if (this.previewImage < this.listImg.length - 1) {
        this.previewImage = this.previewImage + 1
      }
    }
  }
}
</script>

<style lang="scss">
.modal-image {
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    top: 10px;
  }
  .ant-modal-body {
    padding: 56px 8px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    .arrow {
      font-size: 36px;
      cursor: pointer;
      &.disable {
        cursor: not-allowed;
        opacity: 0.3;
      }
    }
  }
}
</style>
