<template>
  <div class="spending">
    <div class="spending__header">
      <div v-if="isUpgrade" class="spending__title">
        Chi tiêu lên hạng
        <span class="capitalize">{{ memberName.toLowerCase() }} </span>
      </div>
      <div class="spending__title" v-else>Chi tiêu:</div>
      <div v-if="needMore" class="expire">
        Chưa đạt
      </div>
      <div v-else class="unexpire">
        Đạt
      </div>
    </div>
    <div>
      <b class="price">{{ (current.totalSpending / 1000).toFixed(0) }}K</b>/
      <b>{{ (current.minSpending / 1000).toFixed(0) }}K</b>
    </div>
    <div class="text-gray-700">
      Chi tiêu thêm: <b>{{ (needMore / 1000).toFixed(0) }}K</b> trước ngày
      <b>{{ moment(current.expire).format('DD/MM/YYYY') }}</b> để {{ isUpgrade ? 'lên' : 'duy trì' }} hạng
      <span class="capitalize">{{ memberName.toLowerCase() }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ItemMemberSpending',
  computed: {
    needMore: function() {
      return this.current.minSpending < this.current.totalSpending
        ? 0
        : this.current.minSpending - this.current.totalSpending
    }
  },
  data() {
    return {}
  },
  props: {
    memberName: {
      type: String,
      default: ''
    },
    current: {
      type: Object,
      default: null
    },
    isUpgrade: {
      type: Boolean,
      default: false
    }
  },
  methods: { moment }
}
</script>

<style lang="scss">
.spending {
  border-top: 1px solid #f2f2f2;
  padding: 8px 0;
  &__header {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  &__title {
    font-weight: 700;
  }
  .price {
    color: red;
  }
}
</style>
