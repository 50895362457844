<template>
  <a-modal
    :visible="visibleFeedback"
    @cancel="handleClose()"
    cancelText="HUỶ"
    okText="XÁC NHẬN"
    :confirmLoading="loading"
    :footer="null"
    :width="canAddNewFeedback ? '1200px' : '430px'"
    wrapClassName="modal-feedback"
    :centered="true"
  >
    <div class="feedback">
      <a-row :class="['feedback__row', canAddNewFeedback ? 'divider' : '']">
        <a-col :span="14" v-if="canAddNewFeedback">
          <div class="feedback__block feedback__update">
            <div class="feedback__title">
              CẬP NHẬT feedback của khách hàng
            </div>

            <div class="feedback__label">
              Người tiếp nhận feedback
              <span class="require">
                *
              </span>
            </div>
            <div class="feedback__input">
              <a-input v-model="user" placeholder="Nhập tên người tiếp nhận feedback" @change="onChangeUserName" />
            </div>
            <div class="feedback__invalid" v-if="messageInvalid.user">
              {{ messageInvalid.user }}
            </div>

            <div class="feedback__label">
              Chọn nhóm lỗi
              <span class="require">
                *
              </span>
            </div>
            <div class="feedback__error">
              <a-row :gutter="[16, 16]">
                <a-col class="" :span="8" v-for="item in listFeedback" :key="item._id">
                  <a-button
                    type="primary"
                    :ghost="!errorSelectedIds.includes(item._id)"
                    block
                    @click="onClickSelectError(item)"
                    >{{ item.name }}</a-button
                  >
                </a-col>
              </a-row>
            </div>
            <div class="feedback__invalid" v-if="messageInvalid.groupError">
              {{ messageInvalid.groupError }}
            </div>
            <div class="feedback__label" v-if="errorSelected.length">
              Chọn chi tiết lỗi
            </div>
            <div class="feedback__detailError" v-for="group in errorSelected" :key="group.name">
              <div class="title">{{ group.name }}</div>
              <div class="content">
                <div
                  :class="[detail.checked ? 'checked' : '', 'content__text']"
                  v-for="detail in group.feedbackDetails"
                  :key="detail.name"
                  @click="onClickSelectErrorDetail(detail, group._id)"
                >
                  <svg
                    v-if="detail.checked"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="Vector"
                      d="M10.2492 0.706055L4.75078 8.01619L1.5 4.74046L0 6.25306L4.99922 11.2943L12 2.21866L10.2492 0.706055Z"
                      fill="#3D3D3D"
                    />
                  </svg>
                  {{ detail.name }}
                </div>
              </div>
            </div>

            <div class="feedback__label">
              Ghi chú thêm của CSKH (nếu có)
            </div>
            <div class="feedback__input">
              <a-textarea
                v-model="note"
                placeholder="Ghi chú thêm của CSKH (nếu có)"
                :auto-size="{ minRows: 4, maxRows: 10 }"
              />
            </div>
            <a-spin :spinning="loading">
              <div class="feedback__action" v-if="errorSelected.length">
                <div class="action" @click="handleClose()">Huỷ</div>
                <div class="action action__confirm" @click="handleConfirmBtn()">Xác nhận</div>
              </div>
            </a-spin>
          </div>
        </a-col>
        <a-col :span="canAddNewFeedback ? 10 : 24" flex="1">
          <div class="feedback__block feedback__list">
            <div class="feedback__title">
              Danh sách feedback đã thêm
            </div>
            <template v-if="dataFeedBack.length">
              <div class="feedback__item" v-for="data in dataFeedBackCustomer" :key="data.name">
                <div class="item__content">
                  <div class="content__title">{{ data.name }}</div>
                  <div class="content__user">Người tiếp nhận: {{ data.recieverName }}</div>
                </div>
                <div class="item__fb">
                  <div class="fb__item" v-for="item in data.feedbacks" :key="item._id">
                    <div class="fb__title">{{ item.name }}</div>
                    <div class="fb__detail" v-if="item.feedbackDetails.length">
                      <span v-for="(detail, index) in item.feedbackDetails" :key="detail.name">
                        {{ detail.name
                        }}{{ item.feedbackDetails.length > 1 && item.feedbackDetails.length - 1 > index ? ',' : '' }}
                      </span>
                    </div>
                  </div>
                  <div class="fb__item" v-if="data.note">
                    <div class="fb__title">Khác</div>
                    <div class="fb__detail">{{ data.note }}</div>
                  </div>
                </div>
              </div>

              <div class="feedback__loadMore" @click="getRenderListFeedBackCustomer(page + 1)" v-if="page < totalPage">
                <span>
                  Xem thêm
                </span>
              </div>
            </template>
            <div v-else class="no-data">
              Không có dữ liệu
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>

<script>
import { RepositoryFactory } from '@/api/RepositoryFactory'
import { mapGetters } from 'vuex'
const feedbackRepository = RepositoryFactory.get('feedbackRepository')

export default {
  name: 'ModalFeedBack',
  data() {
    return {
      loading: false,
      listFeedback: [],
      user: '',
      errorSelected: [],
      note: '',
      messageInvalid: {
        user: '',
        groupError: ''
      },
      errorSelectedIds: [],
      dataFeedBack: [],
      dataFeedBackCustomer: [],
      page: 1,
      totalPage: 1,
      pageId: '/khach-hang-feedback',
      canAddNewFeedback: false
    }
  },
  props: {
    visibleFeedback: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters(['canList']),
    isOpen: {
      get: function() {
        return this.visibleFeedback
      },
      set: function(value) {
        this.$emit('update:visibleFeedback', value)
      }
    }
  },
  async created() {
    this.getListHotlineFeedBack()
  },
  methods: {
    getRenderListFeedBackCustomer(page) {
      this.page = page
      const start = page > 1 ? (page - 1) * 4 : 0
      const end = start + 4
      const newData = [...this.dataFeedBack].slice(start, end)
      if (page === 1) {
        this.dataFeedBackCustomer = []
      }
      this.dataFeedBackCustomer = [...this.dataFeedBackCustomer, ...newData]
    },
    async getListHotlineFeedBack() {
      try {
        const response = await feedbackRepository.getListHotlineFeedBack()
        if (response.data.feedbacks) {
          this.listFeedback = response.data.feedbacks
        }
      } catch (error) {
        throw error
      }
    },
    async getListFeedBackCustomer() {
      try {
        const response = await feedbackRepository.getListFeedbackCustomer(this.customerId)
        if (response?.data?.length) {
          this.dataFeedBack = response.data
          this.page = 1
          this.totalPage = Math.ceil(this.dataFeedBack.length / 4)
          this.getRenderListFeedBackCustomer(1)
        } else {
          this.dataFeedBack = []
          this.totalPage = 1
          this.getRenderListFeedBackCustomer(1)
        }
      } catch (error) {
        this.dataFeedBack = []
        this.totalPage = 1
        this.getRenderListFeedBackCustomer(1)
        throw error
      }
    },
    handleClose() {
      this.note = ''
      this.user = ''
      this.errorSelected = []
      this.errorSelectedIds = []
      this.isOpen = !this.isOpen
      this.getRenderListFeedBackCustomer(1)
    },
    async handleConfirmBtn() {
      if (!this.user) {
        this.messageInvalid = { ...this.messageInvalid, user: 'Chưa nhập Người tiếp nhận feedback' }
        return
      }
      if (this.errorSelected.length === 0) {
        this.messageInvalid = { ...this.messageInvalid, groupError: 'Chưa chọn nhóm lỗi' }
        return
      }
      if (!this.user || this.errorSelected.length === 0) {
        this.messageInvalid = {
          user: !this.user ? 'Chưa nhập Người tiếp nhận feedback' : '',
          groupError: this.errorSelected.length === 0 ? 'Chưa chọn nhóm lỗi' : ''
        }
        return
      }
      if (this.loading) return
      const listFeedback = [...this.errorSelected].map(item => {
        const feedbackDetail = item.feedbackDetails
          .filter(detail => detail.checked === true)
          .map(item => ({ name: item.name }))
        return {
          _id: item._id,
          name: item.name,
          feedbackDetail: feedbackDetail
        }
      })
      try {
        this.loading = true
        const response = await feedbackRepository.createFeedbackCustomer({
          recieverName: this.user,
          customerId: this.customerId,
          feedbacks: listFeedback,
          note: this.note
        })
        if (response?.isSuccess) {
          this.$notification.success({
            message: 'Thông báo',
            description: response.message || 'Thêm feedback khách hàng thành công!'
          })
          this.getListFeedBackCustomer()
          this.note = ''
          this.user = ''
          this.errorSelected = []
          this.errorSelectedIds = []
        } else {
          this.$notification.error({
            message: 'Thông báo',
            description: response.message || 'Có lỗi xảy ra, vui lòng thử lại sau!'
          })
        }
        this.loading = false
      } catch (error) {
        this.$notification.error({
          message: 'Thông báo',
          description: 'Có lỗi xảy ra, vui lòng thử lại sau!'
        })
        this.loading = false
        throw error
      }
      this.loading = false
    },
    onClickSelectError(item) {
      const existErrorSelected = this.errorSelected.find(error => item._id === error._id)
      if (!existErrorSelected) {
        this.errorSelected.push(item)
        this.messageInvalid = { ...this.messageInvalid, groupError: '' }
      } else {
        this.errorSelected = this.errorSelected.filter(error => item._id !== error._id)
      }
      this.errorSelectedIds = this.errorSelected.map(item => item._id)
    },
    onClickSelectErrorDetail(detail, groupId) {
      this.errorSelected = this.errorSelected.map(error => {
        let feedbackDetails = error.feedbackDetails
        if (error._id === groupId) {
          feedbackDetails = error.feedbackDetails.map(fbDetail => ({
            ...fbDetail,
            checked:
              detail.name === fbDetail.name
                ? fbDetail.checked === undefined
                  ? true
                  : !fbDetail.checked
                : fbDetail.checked || false
          }))
        }
        return { ...error, feedbackDetails }
      })
    },
    onChangeUserName() {
      this.messageInvalid = { ...this.messageInvalid, user: '' }
    }
  },
  watch: {
    customerId: function(value) {
      if (value) {
        const canPermNewFeedback = this.canList
          .filter(item => item.subject.includes('/feedback'))
          .filter(item => item.actions === 'Perm_Add')

        this.canAddNewFeedback = canPermNewFeedback.length > 0
        this.getListFeedBackCustomer()
      }
    }
  }
}
</script>

<style lang="scss">
.modal-feedback {
  .ant-modal-body {
    padding: 0;
  }
  .feedback {
    color: #262626;
    &__loadMore {
      text-align: center;
      padding-top: 20px;
      span {
        cursor: pointer;
        text-decoration: underline;
        font-style: italic;
        color: #1890ff;
        font-size: 14px;
        font-weight: 500;
        &:hover {
          color: #2b5277;
        }
      }
    }
    &__row {
      position: relative;
      min-height: 500px;
      &.divider::before {
        position: absolute;
        left: 58.5%;
        top: 0;
        content: '';
        height: 100%;
        width: 2px;
        background: #e8e8e8;
      }
    }
    &__title {
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      color: #262626;
    }
    &__update {
    }
    &__block {
      padding: 20px 30px 20px;
    }
    &__label {
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 10px;
      padding-top: 22px;
      color: #262626;
      span {
        color: #e54d3e;
      }
    }
    &__input {
      input {
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        background: #f6f6f6;
        height: 40px;
      }
    }
    &__error {
    }
    &__invalid {
      color: #e54d3e;
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
    }
    &__detailError {
      margin-bottom: 8px;
      .title {
        font-size: 14px;
        color: #595959;
        font-weight: 600;
        padding-bottom: 8px;
      }
      .content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 12px;
        &__text {
          border-radius: 30px;
          border: 1px solid #e8e8e8;
          background: #f6f6f6;
          font-weight: 500;
          color: #3d3d3d;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 6px 12px;
          img {
            margin-right: 4px;
          }
          &.checked,
          &:hover {
            border: 1px solid #3d3d3d;
            background: #fff;
          }
        }
      }
    }
    &__item {
      border-radius: 10px;
      border: 2px solid #222;
      padding: 20px;
      margin-top: 22px;
      .item {
        &__content {
          .content {
            &__title {
              color: #262626;
              font-size: 16px;
              font-weight: 600;
              text-transform: uppercase;
            }
            &__user {
              color: #262626;
              font-size: 15px;
              font-weight: 600;
            }
          }
        }
        &__fb {
          margin-top: 4px;
          .fb {
            &__item {
              margin-top: 4px;
              display: flex;
            }
            &__title {
              color: #767676;
              font-size: 14px;
              font-weight: 500;
              width: 35%;
              flex: auto;
            }
            &__detail {
              color: #3d3d3d;
              font-size: 14px;
              font-weight: 500;
              width: 65%;
              padding-left: 8px;
              flex: auto;
            }
          }
        }
      }
    }
    &__action {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      gap: 20px;
      .action {
        border-radius: 5px;
        background: #e8e8e8;
        width: 150px;
        height: 40px;
        padding: 10px;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
        &:hover {
          background: #d2d2d2;
        }
        &__confirm {
          background: #fc3;
          &:hover {
            background: rgb(255, 218, 107);
          }
        }
      }
    }
    textarea.ant-input {
      border-radius: 5px;
      border: 1px solid #e8e8e8;
      background: #f6f6f6;
    }
  }
  .no-data {
    text-align: center;
    padding: 25px 0;
  }
}
</style>
